// disabled all click animations for all ant buttons/controls
.ant-wave {
    display: none !important;
}

// Buttons
html body {
    .ant-btn {
        border-radius: 4px;
    }
    .ant-btn-primary {
        padding: 12px 17px;
        box-shadow: none;
        background-color: $electricBlue;
        color: $white;
        border-radius: 4px;

        &:disabled {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }

        &:not(:disabled) {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            border: none !important;

            &:hover {
            }
        }
    }

    .ant-btn-default {
        padding: 12px 24px;
        box-shadow: none;

        &:disabled {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }

        &:not(:disabled) {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            border: none !important;

            &:hover {
            }
        }
    }

    .ant-btn-lg {
        padding: 12px 32px !important;
        font-weight: 400 !important;
        font-size: 24px !important;
        line-height: 28px !important;
        height: 64px !important;
        border-radius: 16px !important;
        letter-spacing: -0.01em;
    }
}

.ant-message {
    z-index: $zNotification;

    .ant-message-notice {
        text-align: right;
    }
}

.ant-card {
    border: none;
    box-shadow: 0px 16px 32px rgba(23, 28, 72, 0.06);
    border-radius: 16px;
    .ant-card-head {
        border-radius: 16px 16px 0 0;
        padding: 20px 40px;
        @media screen and (max-width: 575px) {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    .ant-card-body {
        border-radius: 0 0 16px 16px;
        padding: 24px 40px;
        @media screen and (max-width: 575px) {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}
.ant-form-item {
    margin-bottom: 0;
}
.ant-form-item-explain-error {
    font-size: 0.75rem;
    text-align: left;
    margin-top: 4px;
}

// be careful
html body .ant-checkbox-wrapper {
    margin-bottom: 10px;

    .ant-checkbox {
        align-self: initial;

        & + span {
            position: relative;
            top: -3px;
            padding-inline-start: 16px;
        }

        .ant-checkbox-inner {
            width: 24px;
            height: 24px;
            border-radius: 4px;

            &::after {
                width: 7px;
                height: 13px;
                margin-top: -1px;
                margin-left: 1px;
            }
        }
    }
}

//modal
.ant-modal-wrap {
    display: flex;
    align-items: center;
}
.ant-modal {
    top: 0;
    .ant-modal-content {
        padding: 0;
        border-radius: 8px;
    }

    .ant-modal-header {
        padding: 16px 16px 16px 24px;
        .ant-modal-title {
            color: $textMain;
            font-size: 24px;
            line-height: 28px;
        }
    }

    .ant-modal-content {
        padding: 0;
        border-radius: 8px;
    }

    .ant-modal-header {
        padding: 16px 16px 16px 24px;
        .ant-modal-title {
            color: $textMain;
            font-size: 24px;
            line-height: 28px;
            font-weight: 400;
        }
    }

    .ant-modal-body {
        padding: 24px;
    }

    .ant-modal-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;
    }
}

.ant-select-dropdown {
    z-index: $zNotification + 1;
}

.ant-select-selector {
    .ant-select-selection-overflow {
        gap: 8px;
        .ant-select-selection-overflow-item {
            .ant-select-selection-item {
                padding: 6px 8px !important;
                margin: 0;
                height: 32px;
                border-radius: 4px;
                border: none;
                font-size: 0.875rem;
                font-weight: 500;
            }
        }
    }
}

.ant-select-multiple {
    .ant-select-selector {
        padding: 7px;
        min-height: 48px;
    }
    .ant-select-selection-item-remove svg {
        // TODO change the icon to a custom one
        font-size: 11px;
        path {
            stroke-width: 2px;
        }
    }
}

html body ul.ant-dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px !important;
    padding: 8px 0;
    background-color: $lightGrey !important;

    .ant-dropdown-menu-title-content {
        border-radius: 0;
        padding: 0;
        font-size: 1rem;

        a,
        a:hover {
            text-decoration: none;
            color: $electricBlue;
        }
        a {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
        }
    }

    li.ant-dropdown-menu-item {
        border-radius: 0;
        padding: 12px 16px;
        font-size: 1rem;

        a,
        a:hover {
            text-decoration: none;
            color: $electricBlue;
        }
        a {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
        }
    }
}

.ant-dropdown-menu-item-active {
    background-color: transparent !important;
    padding: 0;
    color: $electricBlue !important;
}

html body {
    .ant-input-password,
    .ant-input {
        background-color: $lightGrey;
        border: 1px solid $border;
        box-shadow: none;
        color: $textMain;
        border-radius: 4px;
    }
    .ant-select-selector {
        background-color: $lightGrey !important;
        border: 1px solid $border !important;
        box-shadow: none !important;
        color: $textMain !important;
        border-radius: 4px !important;
    }
    .ant-input-password,
    .ant-input-disabled,
    .ant-input[disabled],
    .ant-select-disabled .ant-select-selector {
        color: $textLightOpacity;
        background-color: $lightGrey;
        border: 1px solid $border;
    }

    // red required asterisk
    .ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        content: none;
    }

    .ant-input-password,
    .ant-input:focus,
    .ant-input-focused,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused,
    .ant-picker-focused {
        box-shadow: none !important;
    }

    .ant-btn:not(:disabled):focus-visible {
        outline: none;
    }

    .ant-form-item-label > label {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }

    .ant-collapse-item {
        padding: 0;
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .ant-collapse-header {
        padding: 0 !important;
        align-items: center !important;
    }

    .ant-collapse-content-box {
        padding: 0 !important;
        padding-top: 20px !important;
    }
}

.ant-dropdown-menu {
    max-height: 220px;
    overflow: scroll;
}
