.PhoneInputInput {
    background-color: $lightGrey;
    border: 1px solid $border;
    box-shadow: none;
    color: $textMain;
    border-radius: 4px;
    padding: 10.4px 11px;
}

.PhoneInputCountry {
    background-color: $lightGrey;
    border: 1px solid $border;
    box-shadow: none;
    color: $textMain;
    border-radius: 4px;
    padding: 10.4px 11px;
}
