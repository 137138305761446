
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    padding: 20px 140px;
    @media (max-width: 576px) {
        padding: 30px;
    }
}

.title {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;
    color: $textMain;

    @media (max-width: 576px) {
        margin-bottom: 50px;
    }
}

.description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $textLight;
}
