
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    width: 40%;
    max-width: 480px;
    min-width: 240px;
    margin: auto;
    text-align: center;
    margin-bottom: 100px;
    margin-top: 40px;

    .restoreDescr {
        text-align: center;
        margin-bottom: 32px;
    }

    :global {
        .ant-dropdown-trigger {
            background-color: $lightGrey;
            border: 1px solid $border;
            color: $textMain;
            border-radius: 4px;
            padding: 11px 17px;
            margin-left: 2px;
        }
    }

    @media (max-width: 576px) {
        max-width: 100%;
        width: 100%;
    }

    @media (max-width: 786px) {
        max-width: 80%;
        width: 80%;
    }
}

.finishTitle,
.participantTitle,
.title {
    font-weight: 500;
    font-size: 23px;
    line-height: 28px;
    margin-bottom: 44px;
    color: $textMain;
    text-align: center;
}

.finishTitle {
    margin-bottom: 30px;
}

.participantTitle {
    text-align: center;
}

.step,
.subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $textLight;
    margin-bottom: 28px;
}

.step {
    margin-bottom: 16px;
}

.card {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: left;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    background-color: $white;
    padding: 40px 55px;

    @media (max-width: 576px) {
        box-shadow: none;
        padding: 30px;
    }
}

.mobileCard {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: left;
    box-shadow: none;
    background-color: $white;
    padding: 0;
    width: 100%;

    @media (max-width: 576px) {
        width: calc(100% + 90px);
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        padding: 30px;
    }
}

.labelWhite,
.label {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 8px;
    color: $textLight;
    text-align: start;
}

.button {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
}

.inputWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.dropdownText {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: $textMain;
}

.dropdown-item {
    display: flex;
    align-items: center;
    background-color: $lightGrey;
    padding: 0;
}

.dropdown-item:hover {
    color: $electricBlue;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.return-button,
.send-button {
    margin: 10px 0;
    width: 100%;
}

.return-button {
    margin-top: 14px;
}

.progress {
    margin-bottom: 40px;

    :global {
        .ant-progress-steps-item {
            width: 140px !important;
            height: 6px !important;
            background-color: $grey;
        }

        .ant-progress-steps-item-active {
            background-color: $electricBlue;
        }
    }
}

.formItem {
    width: 100%;
    margin-bottom: 20px;
}

.bankText {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: $textLightOpacity;
}

.modal {
    .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
}
.modalTitle {
    font-weight: 500;
    font-size: 23px;
    line-height: 28px;
    margin-bottom: 44px;
    color: $textMain;
}

.checkboxWrapper {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    color: $textLight;
}

.checkboxText {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-left: 10px;
}

.link {
    text-decoration: underline;
    color: $textLight;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.rules {
    max-width: 297px;
    color: $textLight;
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
}

.currencyRule {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (max-width: 786px) {
        align-items: start;
    }
}

.divider {
    height: 1px;
    width: calc(100% + 110px);
    min-width: 240px;
    background-color: rgba(0, 0, 0, 0.1);
    margin-top: 30px;

    @media (max-width: 576px) {
        width: calc(100% + 60px);
    }
}

.cardSender {
    background-color: $electricBlue;
    padding: 12px;
    border-radius: 10px;
}

.labelWhite {
    color: $white;
}
