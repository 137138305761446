
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 36px 140px;
    background-color: $bgMain;
    position: relative;

    @media (max-width: 768px) {
        padding: 20px 30px;
    }
}

header {
    :global {
        .ant-dropdown-menu {
            height: 60px !important;
        }
    }
}

.smallNavbar {
    display: none;
    @media (max-width: 576px) {
        display: flex;
        align-items: center;
    }
}

.burger {
    cursor: pointer;
    margin-left: 20px;
}

.navbar {
    width: 250px;
    @media (max-width: 768px) {
        display: none;
    }
}

.action-group {
    display: flex;
    align-items: center;
}

.action-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $textLight;

    @media (max-width: 768px) {
        font-size: 23px;
        font-weight: 500;
        color: $textMain;
        margin-bottom: 24px;
    }
}

.dropdown {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $textLight;
    cursor: pointer;
}

.burgerMenu {
    position: fixed;
    top: 70px;
    left: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 30px;
    background-color: $white;
    width: 100%;
    min-height: 100vh;
}
