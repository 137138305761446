
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    padding: 20px 140px;
    :global {
        .ant-dropdown-trigger {
            background-color: $lightGrey;
            border: 1px solid $border;
            color: $textMain;
            border-radius: 4px;
            padding: 11px 17px;
            margin-left: 2px;
        }
    }
    @media (max-width: 576px) {
        padding: 20px 30px;
    }
}

.title {
    font-weight: 500;
    font-size: 44px;
    line-height: 50px;
    color: $textMain;
    margin-bottom: 30px;

    @media (max-width: 768px) {
        font-size: 36px;
        line-height: 36px;
        text-align: center;
    }
}

.subtitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: $textLight;

    @media (max-width: 768px) {
        font-size: 18px;
        line-height: 24px;
        text-align: center;
    }
}

.img {
    margin: 80px -60px 70px;

    @media (max-width: 768px) {
        margin: 30px 0;
        width: 100%;
    }
}

.culture-container {
    margin-bottom: 70px;
}

.number {
    color: $electricBlue;
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;

    @media (max-width: 768px) {
        font-size: 23px;
        font-weight: 500;
        margin-bottom: 14px;
    }
}

.rules-title {
    margin: 16px 0;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
}

.rules-subtitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: $textLight;
}

.bottomDescription {
    margin-bottom: 108px;
}
