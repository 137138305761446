
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    width: 40%;
    max-width: 480px;
    min-width: 240px;
    margin: auto;
    text-align: center;
    margin-top: 40px;

    .restoreDescr {
        text-align: center;
        margin-bottom: 32px;
    }

    @media (max-width: 576px) {
        max-width: 100%;
        width: 100%;
    }

    @media (max-width: 786px) {
        max-width: 80%;
        width: 80%;
    }
}

.form {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: left;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    background-color: $white;
    padding: 40px 55px;
    margin-bottom: 50px;
    margin-top: 40px;

    @media (max-width: 576px) {
        box-shadow: none;
        padding: 30px;
    }
}

.title {
    font-weight: 500;
    font-size: 23px;
    line-height: 28px;
    margin-bottom: 44px;
    color: $textMain;
    text-align: center;
}

.stepLabel {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $textLight;
    margin-bottom: 16px;
}

.logo {
    margin-bottom: 2.5rem;
}

.bottomTxt {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $textLight;
    margin-bottom: 6px;
}

.progress {
    margin-bottom: 40px;

    :global {
        .ant-progress-steps-item {
            width: 122px !important;
            height: 6px !important;
            background-color: $grey;
        }

        .ant-progress-steps-item-active {
            background-color: $electricBlue;
        }
    }
}

.formItem {
    width: 100%;
    margin-bottom: 20px;
}

.label {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 8px;
    color: $textLight;
}

.passwordRule {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $textLight;
    width: 60%;
    margin-bottom: 20px;
}

.buttonGoogle,
.button {
    width: 100%;
    margin-top: 20px;
}

.bottomTxt {
    margin-top: 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $textLight;
    margin-bottom: 6px;
}

.buttonGoogle {
    border: 1px solid $lightRed;
    color: $lightRed;
}

.forgot {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $electricBlue;
    margin-top: 8px;
}
