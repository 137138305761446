
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    padding: 20px 140px 100px;
    :global {
        .ant-dropdown-trigger {
            background-color: $lightGrey;
            border: 1px solid $border;
            color: $textMain;
            border-radius: 4px;
            padding: 11px 17px;
            margin-left: 2px;
        }

        .slick-list {
            margin-left: 0 !important;
        }
    }
    @media (max-width: 576px) {
        padding: 20px 30px;
    }
}

.title {
    font-size: 44px;
    font-weight: 500;
    line-height: 50px;
    margin-bottom: 30px;

    @media (max-width: 768px) {
        font-size: 36px;
        line-height: 36px;
        text-align: center;
    }
}

.img-container {
    margin: 0 -70px;
    height: 400px;
    margin-bottom: 64px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;

    @media (max-width: 768px) {
        margin: 0;
        margin-bottom: 20px;
        height: 100%;
    }
}

.smallImg,
.img {
    border-radius: 16px;
    width: 100%;
}

.smallImg {
    margin-bottom: 30px;

    @media (max-width: 768px) {
        margin-bottom: 10px;
    }
}

.type-container {
    display: flex;
    margin-bottom: 12px;
}

.author {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $textMain;
}

.author-container {
    margin-top: 82px;
}

.date {
    font-size: 14px;
    color: $textLight;
    font-weight: 400;
}

.type {
    font-size: 14px;
    font-weight: 400;
    padding: 7px 5px;
    margin-right: 4px;
    border-radius: 4px;
    background: #f3f3f3;
}

.subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 60px;
    color: $textLight;

    @media (max-width: 768px) {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 20px;
    }
}

.content-title {
    font-size: 26px;
    color: $textMain;
    font-weight: 500;
    margin-bottom: 30px;
}

.ps,
.conclusion-title,
.list-title,
.text {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: $textLight;

    @media (max-width: 768px) {
        font-size: 18px;
        line-height: 24px;
    }
}

.list-title {
    color: $textMain;
    margin-bottom: 8px;
}

.conclusion-title {
    color: $textMain;
    margin-bottom: 12px;
}

.ps {
    margin-top: 12px;
}

.topContainer {
    margin-bottom: 112px;
}

.direction-container {
    .title {
        margin-bottom: 50px;
    }
}

.description-container {
    padding: 30px;
    border-radius: 20px;
    background: var(--Lightgrey, #f3f3f3);
    display: flex;
    align-items: flex-start;

    @media (max-width: 768px) {
        margin-bottom: 20px;
        flex-direction: column;
        align-items: center;
    }
}

.description-title {
    font-size: 26px;
    margin-bottom: 16px;
    font-weight: 500;
}

.description-text {
    font-size: 16px;
    font-weight: 400;
    color: $textLight;
}

.divider {
    margin-top: 80px;
    margin-bottom: 80px;
    width: 100%;
    height: 1px;
    opacity: 0.1;
    background: #000;
}

.small-divider {
    width: 100%;
    height: 1px;
    opacity: 0.1;
    background: #000;
}

.slides-divider {
    display: flex;
    margin-bottom: 34px;
    align-items: center;
}
