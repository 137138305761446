
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    padding: 20px 140px;

    @media (max-width: 576px) {
        padding: 30px;
    }
}

.top-content {
    margin-bottom: 100px;

    @media (max-width: 576px) {
        margin-bottom: 30px;
    }
}

.title {
    font-size: 44px;
    font-weight: 500;
    line-height: 50px;
    margin-bottom: 40px;
    color: $textMain;

    @media (max-width: 576px) {
        font-size: 26px;
        line-height: 31px;
        margin-bottom: 50px;
    }
}

.subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $textLight;
}
.button {
    width: 60%;
    margin-top: 50px;
}

.img {
    width: 100%;
}

.description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $textLight;

    @media (max-width: 576px) {
        margin-bottom: 30px;
    }
}

.description-content {
    margin-bottom: 100px;
}

.description-number {
    font-size: 44px;
    color: $electricBlue;
    font-weight: 500;
    line-height: 50px;
}

.description-title {
    margin: 16px 0;
    font-size: 26px;
    font-weight: 500;
    line-height: normal;
}

.description-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $textLight;
}

.modalWrapper {
    padding: 26px 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.label {
    font-size: 14px;
    font-weight: 400;
}

.modalTitle {
    font-size: 23px;
    margin-bottom: 24px;
    font-weight: 500;
    color: $textMain;
    text-align: center;
}

.formItem {
    width: 100%;
    margin-bottom: 20px;
}

.checkButton {
    width: 100%;
    margin-top: 44px;
}
