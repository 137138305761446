.MuiCalendarPicker-root {
    border-radius: 8px;
    padding: 16px;
    margin: 0;
    width: 100% !important;
    height: 328px !important;
    overflow: hidden;

    .MuiPickersCalendarHeader-root {
        margin-top: 0;
        justify-content: space-between;
        padding: 0;
        margin-bottom: 20px;
    }
    .MuiPickersCalendarHeader-labelContainer,
    .MuiPickersCalendarHeader-label {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }
    .MuiDayPicker-header {
        justify-content: space-between;
        margin-bottom: 12px;

        span {
            width: 24px;
            height: 24px;
        }
    }
    .MuiDayPicker-weekDayLabel {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    }
    .MuiDayPicker-weekContainer {
        justify-content: space-between;
        margin: 0;

        .MuiPickersDay-dayWithMargin {
            background-color: transparent;
            border: none;
            width: 24px;
            height: 24px;
            margin: 6px 0;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
        }

        .MuiPickersDay-dayWithMargin:focus {
            border-radius: 4px;
        }

        .MuiPickersDay-today {
            background: none;
            width: 24px;
            height: 24px;
            margin: 6px 0;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            border-radius: 4px;

            &:not(.Mui-selected) {
            }
        }

        .Mui-selected {
            border-radius: 4px;

            &:focus {
                border-radius: 4px;
            }
        }

        .filled {
            .MuiPickersDay-dayWithMargin {
                border: none;
                border-radius: 4px;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
            }

            .Mui-selected {
                border: none;
                border-radius: 4px;
                width: 24px;
                height: 24px;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                border-radius: 4px;
            }
        }

        .skipped {
            .MuiPickersDay-dayWithMargin {
                border-radius: 4px;
                color: $textMain;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
            }

            .Mui-selected {
                border: none;
                border-radius: 4px;
                height: 24px;
            }
        }
    }
}
