
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    padding: 20px 140px;
    :global {
        .ant-dropdown-trigger {
            background-color: $lightGrey;
            border: 1px solid $border;
            color: $textMain;
            border-radius: 4px;
            padding: 11px 17px;
            margin-left: 2px;
        }

        .slick-list {
            margin-left: 0 !important;
        }
    }
    @media (max-width: 576px) {
        padding: 20px 30px;
    }
}

.howStarted {
    padding-left: 140px;
    @media (max-width: 576px) {
        padding-left: 30px;
    }
}

.howStarted-header {
    padding-right: 140px;

    @media (max-width: 576px) {
        padding-right: 30px;
    }
}

.mirror {
    transform: rotate(180deg);
    margin-right: 6px;
}

.title {
    font-weight: 500;
    font-size: 44px;
    line-height: 50px;
    color: $textMain;
    margin-bottom: 30px;

    @media (max-width: 768px) {
        font-size: 36px;
        line-height: 36px;
        text-align: center;
    }
}

.subtitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: $textLight;

    @media (max-width: 768px) {
        font-size: 18px;
        line-height: 24px;
        text-align: center;
    }
}

.img {
    margin: 80px -60px 70px;
    @media (max-width: 768px) {
        margin: 0;
        margin-top: 30px;
        width: 100%;
    }
}

.divider {
    margin-top: 80px;
    margin-bottom: 80px;
    width: 100%;
    height: 1px;
    opacity: 0.1;
    background: #000;
}

.small-divider {
    width: 100%;
    height: 1px;
    opacity: 0.1;
    background: #000;
}

.slides-divider {
    display: flex;
    margin-bottom: 34px;
    align-items: center;
}

.missionContainer {
    margin-top: 100px;

    .title {
        color: $electricBlue;
        font-size: 44px;
        font-weight: 500;
        line-height: 50px;
        margin: 0;
    }

    .subtitle {
        color: $textMain;
        font-size: 44px;
        font-style: normal;
        font-weight: 500;
        line-height: 50px;
    }
}

.mission-description {
    margin-bottom: 12px;
}

.mission-text {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    color: $textLight;
}

.step-description {
    margin-top: 34px;
}

.slides-content {
    margin-right: 40px;
}

.step-title,
.year {
    font-size: 26px;
    color: $electricBlue;
    font-weight: 500;
}

.step-title {
    color: $textMain;
}

.step-text {
    font-size: 16px;
    color: $textLight;
    font-weight: 400;
    margin-top: auto;
}
