
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.footer {
    padding: 40px 140px;
    background-color: $lightGrey;
    @media (max-width: 768px) {
        padding: 36px 30px;
    }
}

.logo {
    @media (max-width: 576px) {
        margin-bottom: 30px;
    }
}

.support,
.subtitle {
    margin-top: 30px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: $textLight;
    width: 60%;
    @media (max-width: 576px) {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
    }
}

.support {
    width: 100%;
    margin-top: 22px;
}

.footerBar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 576px) {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
}

.link {
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $textLight;
    @media (max-width: 576px) {
        text-align: center;
        margin-bottom: 10px;
    }
}

.divider {
    margin: 0 5px;
    height: 16px;
    width: 1.5px;
}

.supportBlock {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.copyright {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.socialLink {
    margin-right: 6px;
    @media (max-width: 576px) {
        margin-right: 10px;
    }
}
