
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    padding: 20px 140px;
    :global {
        .ant-dropdown-trigger {
            background-color: $lightGrey;
            border: 1px solid $border;
            color: $textMain;
            border-radius: 4px;
            padding: 11px 17px;
            margin-left: 2px;
        }
    }
    @media (max-width: 576px) {
        padding: 20px 30px;
    }
}

.calculator-container {
    position: relative;
    padding: 60px 0 380px;
    background-image: none;

    @media (max-width: 768px) {
        padding: 20px 0;
        background-image: none;
    }
}

.imgContainer {
    position: absolute;
    right: 50%;
    top: 200px;
    transform: translate(50%, 50%);
    overflow: hidden;
    border-radius: 30px;
    width: 1280px;
    height: 492px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        padding: 20px 0;
        width: 100%;
        height: 100%;
    }
}

.landscape {
    width: 100%;
    border-radius: 30px;
}

.calc-banner {
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
    padding: 36px 47px;
}

.img-description {
    font-size: 20px;
    font-weight: 400;
}

.calcWrapper {
    @media (max-width: 768px) {
        padding: 34px 35px;
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.25);
    }
}

.title {
    font-weight: 500;
    font-size: 44px;
    line-height: 50px;
    color: $textMain;
    margin-bottom: 30px;

    @media (max-width: 768px) {
        font-size: 36px;
        line-height: 36px;
        text-align: center;
    }
}

.subtitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: $textLight;
    margin-bottom: 40px;

    @media (max-width: 768px) {
        font-size: 18px;
        line-height: 24px;
        text-align: center;
    }
}

.supported-countries,
.methods {
    margin-top: 100px;

    .title {
        margin-bottom: 20px;
    }
}

.supported-countries {
    margin-bottom: 100px;
}

.countries-container {
    margin-top: 60px;

    .description {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        color: $textLight;
    }
}

.methods-description {
    margin-top: 80px;

    .title {
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
    }

    .description {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        color: $textLight;
    }

    @media (max-width: 768px) {
        margin-top: 180px;
    }

    @media (max-width: 544px) {
        margin-top: 100px;
    }
}

.round-flag {
    border-radius: 12px;
    width: 24px;
    height: 24px;
    border: 1px solid $lightGrey;
}

.inputWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $textLight;
}

.dropdownText {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: $textMain;
}

.send-button {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;
}
.banners {
    margin-top: 160px;
    background-image: none;
    @media (max-width: 768px) {
        // padding-top: 260px;
        // background-image: url('../../../public/img/cards.png');
        background-repeat: no-repeat;
        background-position: 40% -3%;
        background-size: 155% auto;
        margin-top: 0;
        margin-bottom: 80px;
    }
}
.advantages-container {
    margin-top: 160px;
    @media (max-width: 768px) {
        margin-top: 0;
    }
}

.advantage {
    background-color: $lightGrey;
    border-radius: 30px;
    overflow: hidden;
    padding: 50px;
    padding-top: 0;
    min-height: 350px;
    @media (max-width: 992px) {
        margin-bottom: 10px;
        padding: 30px 35px;
        min-height: 100px;
    }
}

.advantageBig {
    background-color: $lightGrey;
    border-radius: 30px;
    overflow: hidden;
    padding: 50px;
    padding-top: 0;
    min-height: 350px;
    @media (max-width: 768px) {
        display: none;
    }
}

.advantageCol {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: flex-end;
}

.advantageSmall {
    display: none;

    @media (max-width: 768px) {
        display: flex;
        background-color: $lightGrey;
        border-radius: 30px;
        overflow: hidden;
        padding: 50px;
        padding-top: 0;
        margin-bottom: 10px;
        padding: 30px 35px;
    }
}

.smallImg {
    @media (max-width: 768px) {
        width: 150px;
    }
}

.advantageTitle {
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;
    color: $textMain;
    margin-bottom: 16px;

    @media (max-width: 768px) {
        font-size: 23px;
        font-weight: 500;
    }
}

.advantageDesc {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $textLight;
}

.advantages {
    margin-top: 20px;
    margin-bottom: 160px;

    @media (max-width: 960px) {
        margin-top: 10px;
        margin-bottom: 0;
    }
}

.answer-container {
    margin-bottom: 150px;
    @media (max-width: 960px) {
        margin-bottom: 60px;
        margin-top: 0;
    }

    .title {
        font-weight: 500;
        font-size: 44px;
        line-height: 50px;
        color: $textMain;

        @media (max-width: 768px) {
            font-size: 36px;
            line-height: 36px;
            margin-bottom: 50px;
        }
    }

    .number,
    .ruleTitle {
        font-weight: 500;
        font-size: 26px;
        line-height: 31px;
        margin-bottom: 16px;
        display: flex;

        @media (max-width: 768px) {
            font-size: 23px;
            font-weight: 500;
            margin-bottom: 14px;
        }
    }

    .number {
        color: $electricBlue;
        margin-right: 8px;
        margin-bottom: 0;
    }

    .ruleDesc {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $textLight;
    }
}

.dropdown-item {
    display: flex;
    align-items: center;
    background-color: $lightGrey;
    padding: 0;
}

.dropdown-item:hover {
    color: $electricBlue;
}

.buttonBig {
    width: 100%;
    @media (max-width: 768px) {
        display: none;
    }
}

.buttonSmall {
    display: none;
    @media (max-width: 768px) {
        display: block;
        width: 100%;
    }
}
.rule {
    margin-bottom: 40px;
    @media (max-width: 768px) {
        margin-bottom: 20px;
    }
}
.ruleGroup {
    display: flex;
    align-items: stretch;
}

.progressGroup {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: 36px;
}

.verticalDivider {
    width: 2px;
    flex-grow: 1;
    background: linear-gradient(to bottom, $electricBlue 0%, $electricBlue 60%, $grey 60%, $grey 100%);
}

.greyVerticalDivider {
    width: 2px;
    flex-grow: 1;
    background-color: $grey;
}

.modalWrapper {
    padding: 26px 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.modalTitle {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 30px;
}

.checkStatusWrapper {
    display: flex;
    align-items: center;
}

.checkStatusDis {
    margin-left: 14px;
}

.checkStatus {
    font-size: 16px;
    line-height: 24px;
}

.checkDate {
    color: $textLight;
    font-size: 14px;
    font-weight: 400;
}

.checkAmount {
    color: $electricBlue;
    font-size: 20px;
    font-weight: 400;
}

.checkSendAmount {
    color: $textLight;
    font-size: 14px;
    font-weight: 400;
}

.checkLabel {
    color: $textLight;
    font-size: 16px;
    line-height: 24px;
}

.checkValue {
    font-size: 16px;
    line-height: 24px;
}

.checkButton {
    width: 100%;
    margin-top: 44px;
}
