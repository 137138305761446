
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    padding: 20px 140px;

    @media (max-width: 576px) {
        padding: 30px;
    }
}

.top-content {
    margin-bottom: 100px;

    @media (max-width: 576px) {
        margin-bottom: 30px;
    }
}

.title {
    font-size: 44px;
    font-weight: 500;
    line-height: 50px;
    margin-bottom: 40px;
    color: $textMain;

    @media (max-width: 576px) {
        font-size: 26px;
        line-height: 31px;
        margin-bottom: 50px;
    }
}

.subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $textLight;
}

.partnership {
    display: flex;
    align-items: center;
    border-radius: 10px;
    background: var(--Lightgrey, #f3f3f3);
    margin-bottom: 25px;
    margin-top: 30px;
    padding: 20px 22px;
}

.partnership-text {
    font-size: 16px;
    width: 60%;
    font-weight: 400;
    line-height: 24px;
    color: $textLight;
    margin-left: 29px;
}

.browser-limit {
    color: $electricBlue;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.img {
    width: 100%;
}

.description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $textLight;

    @media (max-width: 576px) {
        margin-bottom: 30px;
    }
}

.limit-content {
    margin-bottom: 59px;
}

.limit-number {
    font-size: 44px;
    color: $electricBlue;
    font-weight: 500;
    line-height: 50px;
}

.limit-ps,
.limit-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.limit-ps {
    color: $textLight;
}
