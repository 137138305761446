
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
}

.img {
    border-radius: 16px;
    width: 360px;
    height: 240px;
    margin-bottom: 20px;
}

.type {
    font-size: 14px;
    font-weight: 400;
    padding: 7px 5px;
    margin-right: 4px;
    border-radius: 4px;
    background: #f3f3f3;
}

.title {
    font-size: 26px;
    font-weight: 500;
    margin: 12px 0;
    color: $textMain;
}

.subtitle {
    color: $textLight;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
