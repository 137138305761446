.wrapper {
    display: flex;
    flex-direction: column;
    background: $bgMain;
    min-height: 100%;
}

.mainContent {
    flex: 1 0;
    flex-basis: 0px;
}

.header {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    padding: 12px 0;

    .header-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1200px;
        margin: auto;
    }

    .nav-item {
        text-decoration: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $textMain;
        padding: 8px 16px;
    }

    .active {
    }

    .dropdownBtn {
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .dropdownBtnTitle {
        font-size: 1rem;
        font-weight: 500;
        color: $textMain;
        margin: 0;
    }

    .dropdownBtnSubtitle {
        font-size: 0.875rem;
        color: $textLight;
        margin: 0;
        line-height: 1rem;
    }
}

.dropdownMenu {
    box-shadow: 0px 8px 12px rgba(23, 28, 72, 0.16);
    border-radius: 0px 0px 8px 8px;
    :global {
        .ant-dropdown-menu {
            border-radius: 0px 0px 8px 8px;
            box-shadow: none;
            padding: 0;
            .ant-dropdown-menu-item {
                a,
                a:hover {
                    color: $textMain;
                    &.blue {
                    }
                }
                padding: 12px 16px;
                border-radius: 0;
            }
            .ant-dropdown-menu-item-icon {
                min-width: 0;
                margin-inline-end: 0;
            }
            .anticon.anticon-check.green path {
            }
        }
    }
}
