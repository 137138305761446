// todo change it to CSS variables

$bgMain: #ffffff;

$textMain: #2b2d38;
$white: #ffffff;
$textLight: #747d96;
$textLightOpacity: #2b2d384c;
$border: #0000001a;
$electricBlue: #0085ff;
$primary: #0085ff;
$blue: #0080ff;
$red: #ed254e;
$lightRed: #ff6464;

$lightGrey: #f3f3f3;
$grey: #dddfe7;

$zConferenceButtons: 2;
$zScreenSharingVideo: 100;
$zColorController: 1000;
$zScheduleTooltip: 2000;
$zVideoConference: 9800;
$zActiveProcedure: 9900;
$zScreenSharingVideoFullScreen: 9910;
$zPauseOverlay: 9970;
$zNotification: 10200;
.ant-modal-mask {
    z-index: 10000 !important;
}
.ant-modal-wrap {
    z-index: 10100 !important;
}

$zGlobalLoader: 99999;
