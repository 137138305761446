
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
    max-width: 1200px;
    margin: auto;
    padding: 48px 0;
    position: relative;

    :global {
        .ant-dropdown-trigger {
            background-color: $lightGrey;
            border: 1px solid $border;
            color: $textMain;
            border-radius: 4px;
            padding: 11px 17px;
            margin-left: 2px;
            color: $textLight;
        }
    }
}

.historyRow {
    cursor: pointer;
}

.editBanner,
.historyBanner {
    background: #ffffff;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 50px 60px;
    @media (max-width: 576px) {
        width: 100%;
        margin-bottom: 50px;
        display: inline-block;
    }
}

.editBanner {
    padding: 50px 55px;
    @media (max-width: 576px) {
        box-shadow: none;
        padding: 0 30px;
    }
}

.title {
    font-weight: 500;
    font-size: 23px;
    line-height: 28px;
    margin-bottom: 20px;
}

.userData {
    @media (max-width: 576px) {
        padding: 0 30px;
    }
}

.name {
    font-weight: 500;
    font-size: 44px;
    line-height: 50px;

    @media (max-width: 576px) {
        font-size: 23px;
        font-weight: 500;
        text-align: center;
    }
}

.infoContainer {
    margin: 50px 0;
}

.divider {
    background-color: rgba(0, 0, 0, 0.1);
    height: 1px;
    width: 100%;
}

.infoText {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 14px 0;
}

.infoActions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.delete,
.actionText,
.edit {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $blue;
}

.actionText {
    color: $textLight;
}

.delete {
    color: $red;
}

.formItem {
    width: 100%;
    margin-bottom: 20px;
}

.dropdownText {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: $textLight;
}

.dropdown-item {
    display: flex;
    align-items: center;
    background-color: $lightGrey;
    padding: 0;
}

.dropdown-item:hover {
    color: $electricBlue;
}

.button {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
}

.emptyHistory {
    font-size: 16px;
    line-height: 24px;
}

.historyData {
    margin-top: 22px;
}

.historyRow {
    margin-bottom: 35px;

    :last-child {
        margin-bottom: 0;
    }
}

.cards {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.cardNumber {
    font-size: 16px;
    line-height: 24px;
}

.transferDate {
    color: $textLight;
    font-size: 14px;
}

.amount {
    text-align: right;
    font-size: 16px;
    line-height: 24px;
}

.checkWrapper {
    padding: 26px 31px;
}

.checkHeader {
    margin-bottom: 40px;
}

.checkStatusWrapper {
    display: flex;
    align-items: center;
}

.checkStatusDis {
    margin-left: 14px;
}

.checkStatus {
    font-size: 16px;
    line-height: 24px;
}

.checkDate {
    color: $textLight;
    font-size: 14px;
    font-weight: 400;
}

.checkAmount {
    color: $electricBlue;
    font-size: 20px;
    font-weight: 400;
}

.checkSendAmount {
    color: $textLight;
    font-size: 14px;
    font-weight: 400;
}

.checkLabel {
    color: $textLight;
    font-size: 16px;
    line-height: 24px;
}

.checkValue {
    font-size: 16px;
    line-height: 24px;
}

.checkButton {
    width: 100%;
    margin-top: 44px;
}

.bannerTitle {
    font-size: 23px;
    font-weight: 500;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 40px;
}
